// -------------------------------------------------------------
// This file contains styles for ItsAMonkey page banners.
// -------------------------------------------------------------

@import 'libs/mixins';

.page-header {
    position: relative;
    top: 0;
    height: 115px;
    background-color: $color-grey-dark;

    @include mediaBreakpoint(tablet) { height: 90px !important; }
    @include mediaBreakpoint(mobile) { height: 90px !important; }


    .ui.text.container {
        background: transparent;
        height: 100%;
        width: 100%;
        padding-top: 160px;
        text-align: center;

        @include mediaBreakpoint(mobile) { width: 100% !important; padding-top: 150px; margin-left: 0 !important; }

        h1.ui.header {
            @include fontWeight(normal);
            font-size: 30px;
            color: $color-white;
            line-height: 50px;
            padding-bottom: 0;
            width: 80%;
            display: inline-block;
            text-transform: uppercase;

            @include mediaBreakpoint(mobile) { width: 100%; left: 0; }
        }

        .ui.form.subscribe {
            padding-top: 7px;
            padding-left: 10px;
            padding-right: 10px;

            input {
                width: 475px;

                @include mediaBreakpoint(mobile) { width: 350px !important; }

                &:focus {
                    border-color: $color-blue;
                }
            }

            .message {
                padding-top: 5px;
            }
        }
    }
}
