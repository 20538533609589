// -------------------------------------------------------------
// This file contains styles for the transparent main menu.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.secondary.menu {
    font-size: 14px;
    background: transparent;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 4;

    @include mediaBreakpoint(tablet) { top: 0px; font-size: 20px; }
    @include mediaBreakpoint(mobile) { top: 0px; font-size: 20px; }

    .ui.container {
        max-width: $text-container-width !important;
        width: $text-container-width !important;
    }

    .left.item {
        padding-left: 0;
        @include mediaBreakpoint(tablet) { margin-left: 0 !important; padding-left: 0 !important; }
        @include mediaBreakpoint(mobile) { margin-left: 0 !important; padding-left: 0 !important; }

        a.item {
            color: $color-white;
            margin-bottom: 12px;
            padding-right: 0;
            padding-left: 0;
            margin-left: 0;
            font-size: 30px;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            @include mediaBreakpoint(tablet) { font-size: 25px; margin: 0; margin-bottom: 10px; padding: 0; }
            @include mediaBreakpoint(mobile) { font-size: 25px; margin: 0; margin-bottom: 5px; padding: 0; }

            .fa-stack {
                font-size: 20px;

                &.page {
                    @include mediaBreakpoint(smobile) { display: none; }
                }

                .fa-circle {
                    color: $color-blue;
                }
            }

            &:first-child {
                margin-right: 10px;

                @include mediaBreakpoint(tablet) { margin-right: 5px !important; margin-left: 20px; }
                @include mediaBreakpoint(mobile) { margin-right: 5px !important; margin-left: 20px; }
            }

            &:hover {
                color: $color-white;
                background-color: transparent;
            }

            &.logo {
                margin-bottom: 17px;
                margin-left: 5px;
                background: transparent no-repeat top left;
                background-image: url('/static/img/logo_small_sprite.png');
                background-position: 0 0;
                background-size : 100% 200%;
                text-decoration: none;
                display: block;
                width: 197px;
                height: 47px;
                border: none;

                &.hidden {
                    display: none;
                }

                @include mediaBreakpoint(tablet) {
                    margin-left: 10px;
                }

                @include mediaBreakpoint(mobile) {
                    margin-top: 5px;
                    margin-left: 10px;
                    padding-right: 0;
                }
            }
        }
    }

    .right.item {
        margin-left: 110px !important;
        padding-left: 0 !important;
        padding-right: 0;
        background: transparent !important;

        @include mediaBreakpoint(tablet) { margin-left: 0 !important; margin-right: 0 !important; padding-right: 0 !important; }
        @include mediaBreakpoint(mobile) { margin-left: 0 !important; margin-right: 0 !important; padding-right: 0 !important; }

        a.item {
            @include fontWeight(light);
            color: $color-white;
            text-transform: uppercase;

            @include mediaBreakpoint(tablet) { display: none; }
            @include mediaBreakpoint(mobile) { display: none; }

            &:hover {
                color: $color-blue;
                background-color: transparent;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        a.toc.item {
            display: none;
            padding-right: 0;
            color: $color-white;
            background: transparent !important;
            margin: 0 !important;

            @include mediaBreakpoint(tablet) { display: block; }
            @include mediaBreakpoint(mobile) { text-align: left; display: block; padding-left: 0.5em; }
        }

        a.item.button {
            @include fontWeight(bold);
            color: $color-white;
            font-size: 14px;
            text-transform: uppercase;
            border-bottom: 2px solid $color-white;
            padding-bottom: 1px;
            margin-bottom: 1px;
            border-radius: 0;
            margin-left: 20px;
            padding-left: 0;
            padding-right: 0;

            @include mediaBreakpoint(tablet) { display: none; }
            @include mediaBreakpoint(mobile) { display: none; }

            &:last-child {
                margin-left: 35px;
            }

            &:hover {
                color: $color-blue;
                border-color: $color-blue;
            }
        }
    }
}
