// -------------------------------------------------------------
// This file contains styles for the testimonials tweets
// on the It's A Monkey homepage.
// -------------------------------------------------------------

@import 'libs/mixins';

.testimonials {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 60px;

    @include mediaBreakpoint(mobile) { margin-bottom: 0px; padding-bottom: 0 !important; }
    
    .EmbeddedTweet-tweet {
        width: 200px !important;
    }

    &:after {
        content: " ";
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        right: -300px;
        background-image: -webkit-linear-gradient(left, rgba(255,255,255,0), #fff),
        -webkit-linear-gradient(left, rgba(255,255,255,0), #fff);
        background-image: -o-linear-gradient(left, rgba(255,255,255,0), #fff),
        -o-linear-gradient(left, rgba(255,255,255,0), #fff);
        background-image: linear-gradient(to right, rgba(255,255,255,0), #fff),
        linear-gradient(to right, rgba(255,255,255,0), #fff);
        z-index: 10;
    }

    &:before {
        content: " ";
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        left: -250px;
        background-size: 100%;
        background-image: -webkit-linear-gradient(right, rgba(255,255,255,0), #fff),
        -webkit-linear-gradient(right, rgba(255,255,255,0), #fff);
        background-image: -moz-linear-gradient(right, rgba(255,255,255,0), #fff),
        -moz-linear-gradient(right, rgba(255,255,255,0), #fff);
        background-image: -ms-linear-gradient(right, rgba(255,255,255,0), #fff),
        -ms-linear-gradient(right, rgba(255,255,255,0), #fff);
        background-image: -o-linear-gradient(right, rgba(255,255,255,0), #fff),
        -o-linear-gradient(right, rgba(255,255,255,0), #fff);
        z-index: 10;
    }
}

.alternate .testimonials {
    &:after {
        background-image: -webkit-linear-gradient(left, rgba(255,255,255,0), #f7f7f7),
        -webkit-linear-gradient(left, rgba(255,255,255,0), #f7f7f7);
        background-image: -o-linear-gradient(left, rgba(255,255,255,0), #f7f7f7),
        -o-linear-gradient(left, rgba(255,255,255,0), #f7f7f7);
        background-image: linear-gradient(to right, rgba(255,255,255,0), #f7f7f7),
        linear-gradient(to right, rgba(255,255,255,0), #f7f7f7);
    }

    &:before {
        background-image: -webkit-linear-gradient(right,rgba(255,255,255,0),#f7f7f7),
        -webkit-linear-gradient(right,rgba(255,255,255,0),#f7f7f7);
        background-image: -moz-linear-gradient(right,rgba(255,255,255,0),#f7f7f7),
        -moz-linear-gradient(right,rgba(255,255,255,0),#f7f7f7);
        background-image: -ms-linear-gradient(right,rgba(255,255,255,0),#f7f7f7),
        -ms-linear-gradient(right,rgba(255,255,255,0),#f7f7f7);
        background-image: -o-linear-gradient(right,rgba(255,255,255,0),#f7f7f7),
        -o-linear-gradient(right,rgba(255,255,255,0),#f7f7f7);
    }
}