$color-white-primary: #FFFFFF;
$color-black-primary: #000000;

$color-grey-primary: #576060;
$color-grey-secondary: #EEE;

$color-red-primary: #F41B40;
$color-red-secondary: #ce2037;

$color-twitter: #1EABE3;
$color-twitter-dark: #1C80A8;

$color-foam-primary: #f5fbfe;

$color-blue: #00aeef;
$color-blue-dark: #0095cc;
$color-hover: #4e7ab5;

$color-green: #a6ce39;
$color-green-dark: #86b700;

$color-grey: #cfcfcf;
$color-grey-dark: #414141;

$color-white: #FFFFFF;
$color-black: #000000;
$color-red: #F41B40;

$font-size-xsmall: 16px;
$font-size-small: 20px;
$font-size-medium: 25px;
$font-size-large: 32px;
$font-size-xlarge: 41px;
$font-size-xxlarge: 60px;

$text-container-width: 1145px;

/* Breakpoints
...................................................................................... */

@mixin mediaBreakpoint($point) {
  @if $point == 'large' {
    @media (min-width:  1800px) { @content; }
  }
  @if $point == 'laptop' {
    @media (min-width: 1024px) and (max-width: 1440px) { @content; }
  }
  @if $point == 'tablet' {
    @media only screen and (min-width: 768px) and (max-width: 1024px) { @content; }
  }
  @if $point == 'mobile' {
    @media only screen and (max-width: 767px)  { @content; }
  }
  @else if $point == 'smobile' {
    @media only screen and (max-width: 320px)  { @content; }
  }
}

/* Helpers
...................................................................................... */

@mixin fontWeight($weight) {
  @if $weight == 'light' {
    font-weight: 200;
  }
  @if $weight == 'normal' {
    font-weight: 300;
  }
  @if $weight == 'bold' {
    font-weight: 400;
  }
}

@mixin grayScale($percentage) {
  $filter: gray;
  @if $percentage == '0%' {
    $filter: none;
  }
  filter: grayscale($percentage);
  -webkit-filter: grayscale($percentage);
  filter: $filter;
}

@mixin roundCornerAll($radius) {
  @include roundCorner($radius, $radius, $radius, $radius);
}

@mixin roundCorner($top, $right, $bottom, $left) {
  -moz-border-radius: $top $right $bottom $left;
  -webkit-border-radius: $top $right $bottom $left;
  -khtml-border-radius: $top $right $bottom $left;
  border-radius: $top $right $bottom $left;
}

@mixin verticalAlign($where: middle) {
  vertical-align: $where;
  display:inline-block;
}

@mixin opacity($value) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $value*100 + ")"; // IE 8
  filter: alpha(opacity=$value*100); // IE 5-7
  -moz-opacity: $value; // Netscape
  -khtml-opacity: $value; // Safari 1.x
  opacity: $value;
}

@mixin transform($value, $origin:center) {
  -webkit-transform:  $value;
  -moz-transform:     $value;
  -ms-transform:      $value;
  -o-transform:       $value;
  transform:          $value;

  -webkit-transform-origin:  $origin;
  -moz-transform-origin:     $origin;
  -ms-transform-origin:      $origin;
  -o-transform-origin:       $origin;
  transform-origin:          $origin;
}

@mixin backgroundHover($backgroundColor, $percentage:5%) {
  background: $backgroundColor;

  &:hover {
    background: darken($backgroundColor, $percentage);
  }
}

@mixin backgroundClick($backgroundColor, $percentage:10%) {
  background: $backgroundColor;

  &:active {
    background: darken($backgroundColor, $percentage);
  }
}

@mixin foregroundHover($foregroundColor, $percentage:5%) {
  color: $foregroundColor;

  &:hover {
    color: darken($foregroundColor, $percentage);
  }
}

@mixin foregroundClick($foregroundColor, $percentage:10%) {
  color: $foregroundColor;

  &:active {
    color: darken($foregroundColor, $percentage);
  }
}

@mixin fullTransition($timer) {
  -webkit-transition:   all $timer ease;
    -moz-transition:    all $timer ease;
      -ms-transition:   all $timer ease;
        -o-transition:  all $timer ease;
          transition:   all $timer ease;
}

@mixin selectTransition($values...) {
  -webkit-transition: $values;
    -moz-transition: $values;
      -ms-transition: $values;
        -o-transition: $values;
          transition: $values;
}

@mixin hoverTransition($timer) {
  @include fullTransition($timer);

  &:active, &:hover {
    @include noTransition();
  }
}

@mixin selectHoverTransition($transition) {
  @include selectTransition($transition);

  &:active, &:hover {
    @include noTransition();
  }
}

@mixin noTransition() {
  -webkit-transition: none;
    -moz-transition: none;
      -ms-transition: none;
        -o-transition: none;
          transition: none;
}

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin animationDelay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay:    $delay;
  -o-animation-delay:      $delay;
  animation-delay:         $delay;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin unselectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin selectable() {
  -webkit-touch-callout: default;
  -webkit-user-select: auto;
  -khtml-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
}

@mixin removeInputGlow() {
  &:focus {
    outline-color: transparent;
    outline-style: none;
  }
}

@mixin grabbable() {
  cursor: move; // Fallback incase grab isn't supported
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active, &.active { // Make the cursor actually "grab" on click - The :active psuedoselector is sometimes screwed in firefox, just apply a class using js to active this
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

/* Classes
...................................................................................... */

.height-fix {
  vertical-align: middle;
  height: 100%;
  width:0;
  display: inline-block;
  overflow:hidden;
  clear: both;
}

.inline-middle {
  display:inline-block;
  vertical-align: middle;
}

.days-active {
  color: $color-red-primary;
  font-size: $font-size-small;
  @include fontWeight(normal)
}

.mf-red {
    color: #ef3f57;
}