// -------------------------------------------------------------
// This file contains global styles for the ManageFlitter
// layout and pages.
// -------------------------------------------------------------

@import 'libs/mixins';

@font-face {
    font-family: 'Mono Social Icons Font';
    src: url('/static/fonts/MonoSocialIconsFont-1.10.eot');
    src: url('/static/fonts/MonoSocialIconsFont-1.10.eot?#iefix') format('embedded-opentype'),
         url('/static/fonts/MonoSocialIconsFont-1.10.woff') format('woff'),
         url('/static/fonts/MonoSocialIconsFont-1.10.ttf') format('truetype'),
         url('/static/fonts/MonoSocialIconsFont-1.10.svg#MonoSocialIconsFont') format('svg');
    src: url('/static/fonts/MonoSocialIconsFont-1.10.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body.pushable {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    background-color: $color-grey-dark !important;
}

.pusher {
    background-color: $color-grey-dark !important;
}

.ui.active.transparent.dimmer {
    background-color: transparent !important;
}

b, optgroup, strong {
    @include fontWeight(bold);
}

a:focus {
    outline: none;
}

li {
    padding-bottom: 10px;
}

.not-found {
    .ui.header {
        padding-bottom: 30px !important;
    }

    p {
        padding-left: 0;
    }

    .text {
        padding-bottom: 60px;

        a.contact {
            color: $color-blue !important;
        }
    }
}

.symbol, a.symbol:before {
    font-family: 'Mono Social Icons Font';
    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

a.symbol:before {
    content: attr(title);
    margin-right: 0.3em;
    font-size: 130%;
}
