// -------------------------------------------------------------
// This file contains global styles for the It's a Monkey
// Monkey contact modal window.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.small.modal.contact {
    .header {
        padding-top: 0px;
    }

    .submit-message {
        display: none;

        .title {
            padding-top: 150px;
            text-transform: uppercase;
            @include fontWeight(bold);
        }

        .message {
            padding-bottom: 200px;
        }

        .closeWindow {
            width: 100%;
            margin-bottom: 50px;
        }
    }
    .content {
        .description {
            padding-bottom: 0px;
        }

        .ui.form.subscribe {
            .ui.input.icon {
                padding-bottom: 20px;

                &:last-child {
                    padding-bottom: 0;
                }
            }

            input {
                @include fontWeight(light);
                background-color: $color-white;
                color: $color-grey-dark !important;
                width: 353px;
            }

            textarea {
                @extend input;
                border-radius: 0;
                border: 1px solid $color-blue;

                &:focus {
                    border-color: $color-green;
                }
            }

            .message {
                padding-top: 10px;
            }

            .ui.button {
                width: 353px;

                @include mediaBreakpoint(mobile) { width: 100% !important; }
            }
        }
    }
}
