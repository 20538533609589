// -------------------------------------------------------------
// This file contains styles for the main carousel on the 
// It's A Monkey homepage.
// -------------------------------------------------------------

@import 'libs/mixins';

.monkey-carousel {
	position: absolute !important;
	width: 100% !important;
	left: 0;
	z-index: 2;
	top: 500px;
	
	@include mediaBreakpoint(mobile) { top: 400px; }

	.owl-controls {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 100px;
		z-index: 4;

		@include mediaBreakpoint(mobile) { top: 150px; }

		.owl-page {
			display: inline-block;
			zoom: 1;
			*display: inline;
			cursor: pointer;

			span {
				display: block;
				width: 10px;
				height: 10px;
				margin: 5px 7px;
				filter: Alpha(Opacity=50);
				opacity: 0.5;
				-webkit-border-radius: 20px;
				-moz-border-radius: 20px;
				border-radius: 20px;
				background: $color-grey;
				border: 1px solid $color-grey;
			}
		}

		.owl-page.active span,
		&.clickable .owl-page:hover span {
			filter: Alpha(Opacity=100);
			opacity: 1;
			background: $color-grey;
			border-color: none;
		}
	}

	.owl-item.loading {
		min-height: 150px;
		background: url('/static/img/grey-loading.gif') no-repeat center center
	}
}