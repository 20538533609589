// -------------------------------------------------------------
// This file contains styles for the Recent Episodes section on
// the It's A Monkey homepage.
// -------------------------------------------------------------

@import 'libs/mixins';

.recent-episode {
    margin-top: 0 !important;

    &.hidden {
        display: none;
    }

    .image {
        img {
            width: 100%;
        }

    }

    .content {
        padding-left: 30px !important;

        @include mediaBreakpoint(tablet) {
            padding-left: 20px !important;
        }
    }

    .buttons {
        padding-top: 20px;

        .ui.button {
            margin-right: 0 !important;
            width: 100% !important;

            @include mediaBreakpoint(mobile) {
                background-color: $color-blue;
                border-color: $color-blue;
            }

            &:hover {
                @include mediaBreakpoint(mobile) {
                    color: $color-white;
                    border-color: $color-blue-dark;
                    background-color: $color-blue-dark;
                }
            }
        }
    }

    .title {
        @include fontWeight(bold);
        font-size: 30px;
        line-height: 24px;
        height: 24px;
        margin-bottom: 10px;
        text-transform: uppercase;

        a {
            color: $color-grey-dark;

            &:hover {
                color: $color-blue;
            }
        }

        @include mediaBreakpoint(tablet) {
            font-size: 25px;
        }
        @include mediaBreakpoint(mobile) {
            padding-top: 5px;
            margin-bottom: 0;
            font-size: 20px;
            height: auto;
        }
    }

    .subtitle {
        font-size: 20px;
        padding-bottom: 0px;
        text-transform: uppercase;
        @include fontWeight(light);

        @include mediaBreakpoint(tablet) {
            font-size: 18px;
            padding-bottom: 5px;
        }
        @include mediaBreakpoint(mobile) {
            font-size: 17px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        a:hover {
            color: $color-blue;
        }

        .divider {
            font-style: normal;
        }
    }

    .meta {
        padding-bottom: 30px;
        padding-top: 10px;
        font-size: 15px;
        @include fontWeight(light);

        .guest-link {
            padding-right: 10px;

        }

        .date-comments {
          font-style: italic;
        }

        a {
            color: $color-grey-dark;

            &:hover {
                color: $color-blue;
            }
        }
    }

    .description {
        font-size: 17px;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 5px;
        @include fontWeight(light);

        @include mediaBreakpoint(tablet) {
            padding-top: 10px;
            width: 100% !important;
        }
        @include mediaBreakpoint(mobile) {
            padding-top: 10px;
            width: 100% !important;
        }

        &.no-player {
            padding-top: 10px;
        }

        ul {
            padding-left: 20px;
        }

        a {
            color: $color-blue;

            &:hover {
                color: $color-blue-dark;
            }
        }
    }
}
