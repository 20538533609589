// -------------------------------------------------------------
// This file contains styles for the individual episode page on
// the It's A Monkey website.
// -------------------------------------------------------------

@import 'libs/mixins';

.all-episodes {
    position: relative;

    p {
        padding-left: 15px;
    }

    a {
        color: $color-white;
    }

    .ui.text.container {
        @include mediaBreakpoint(tablet) {
            margin: 0 !important;
            padding-left: 20px;
            padding-right: 20px;
        }

        @include mediaBreakpoint(mobile) {
            margin: 0 !important;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .ui.form {
        width: 30%;

        @include mediaBreakpoint(tablet) { width: 100%; margin-left: 0px; display: block !important; }
        @include mediaBreakpoint(mobile) { width: 100%; margin-left: 0px; display: block !important; }

        .ui.input {
            @include mediaBreakpoint(tablet) { margin-right: 10px; }
            @include mediaBreakpoint(mobile) { margin-right: 10px; }
        }
    }

    .ui.cards {
        @include mediaBreakpoint(tablet) { margin-top: 10px !important; padding-right: 0; margin-right: 0; }
        @include mediaBreakpoint(mobile) { margin-top: -20px !important; padding-top: 10px; }
    }

    .ui.stackable.cards > .card {
        @include mediaBreakpoint(tablet) { width: 100%; padding-right: 0px; margin-right: 0; }
        @include mediaBreakpoint(mobile) { padding-right: 10px; }

        &:first-child {
            @include mediaBreakpoint(mobile) { margin-top: 20px !important; }
        }
    }

    &.popular {
        .ui.card {
            background-color: #E9E9E9;

            @include mediaBreakpoint(tablet) { background-color: $color-green; }
            @include mediaBreakpoint(mobile) { background-color: $color-green; }
        }
    }

    .ui.card {
        border-radius: 0 !important;
        background-color: $color-white;
        margin-bottom: 80px !important;
        margin-left: 0;
        box-shadow: none;

        @include mediaBreakpoint(tablet) {
            background-color: $color-green;
            margin-bottom: 40px !important;
            width: 100% !important;
        }

        @include mediaBreakpoint(mobile) {
            background-color: $color-green;
            margin-bottom: 40px !important;
            width: 100% !important;
        }

        &:last-child {
            .content {
                @include mediaBreakpoint(mobile) { border: none; }
            }
        }

        .image {
            position: relative;
            cursor: pointer;
            border-radius: 0 !important;

            img {
                max-height: 280px;

                @include mediaBreakpoint(tablet) { max-height: none; }
                @include mediaBreakpoint(mobile) { max-height: none; }
            }

            .episode-no {
                position: absolute;
                @include fontWeight(bold);
                font-size: 40px!important;
                color: $color-white;
                background-color: $color-green;
                text-align: center;
                padding: 10px 20px 10px 20px;
            }

            @include mediaBreakpoint(tablet) { width: 100%; }
            @include mediaBreakpoint(mobile) { width: 100%; }
        }

        .content {
            background-color: $color-green;
            padding: 0 !important;
            border: none;

            @include mediaBreakpoint(tablet) {
                padding: 0 !important;
                padding-bottom: 5px;
                width: 100%;
            }

            @include mediaBreakpoint(mobile) {
                padding-left: 0;
                width: 100%;
            }

            .description {
                font-size: 17px;
                color: $color-white;
                padding: 10px 20px 20px 20px;
                @include fontWeight(normal);

                @include mediaBreakpoint(tablet) { padding-left: 20px; }
                @include mediaBreakpoint(mobile) { padding-left: 20px; }
            }

            .meta {
                @include fontWeight(normal);
                font-size: 15px;
                padding-bottom: 0;
                color: $color-white;
                background-color: #87BC17;
                width: 100%;
                font-style: italic;
                padding: 10px;
                padding-left: 20px;

                @include mediaBreakpoint(tablet) { display: none; }
                @include mediaBreakpoint(mobile) { display: none; }

                a {
                    color: $color-white;

                    &:hover {
                        color: $color-hover;
                    }
                }

                .divider {
                    font-style: normal;
                }

                .listen-now {
                    text-transform: uppercase;
                }
            }

            .interview {
                padding-bottom: 30px;

                a.guest-link:hover {
                    color: $color-blue;
                }

                .name {
                    font-size: 24px;
                    text-transform: uppercase;
                }

                .title {
                    font-size: 20px;
                }
            }

            .news-divider {
                border-top: 1px solid $color-white;
                width: 20%;
            }

            .news {
                font-size: 16px;
                padding-top: 20px;

                .title {
                    text-transform: uppercase;
                    font-size: 20px;
                }
            }

            .read-more {
                text-align: center;
                padding-top: 0px;
                margin: 0 5px 0 20px;
                display: none;

                .ui.button {
                    background-color: $color-blue;
                    border-color: $color-blue;
                }

                @include mediaBreakpoint(tablet) { display: block; padding-bottom: 10px; }
                @include mediaBreakpoint(mobile) { display: block; padding-bottom: 10px; }
            }
        }

        .ui.button {
            margin-top: 10px;
            margin-left: 0px !important;
            margin-right: 0px !important;
            padding: 2px 15px 5px 12px !important;
            box-sizing: border-box;
            height: 33px;
            line-height: normal;
            @include fontWeight(light);
            width: auto !important;
            background-color: $color-blue;
            border-color: $color-blue;
            border-radius: 0 !important;

            &:hover {
                background-color: $color-blue-dark;
                border-color: $color-blue-dark;
            }

            &.listen {
                @include mediaBreakpoint(tablet) { display: none; }
                @include mediaBreakpoint(mobile) { display: none; }
            }
        }
    }

    .load-more {
        background-color: $color-white;
        width: 100%;
        text-align: center !important;
    }
}
