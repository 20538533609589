// -------------------------------------------------------------
// This file contains styles for the Form component.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.form.subscribe {
    padding-top: 20px;

    &.error {
        .ui.icon.input > input[name='links'] {
            border-color: $color-white !important;
        }
        .ui.icon.input > input, textarea {
            border-color: $color-red !important;
        }

        .ui.button {
            background-color: $color-white;
            border-color: $color-red;
            color: $color-red;
        }
    }

    &.success {
        .ui.icon.input > input, textarea {
            border-color: $color-white !important;
        }

        .ui.button {
            background-color: $color-white;
            border-color: $color-green;
            color: $color-green;
        }
    }

    .message {
        @include fontWeight(normal);
        font-size: 16px;
        color: $color-white;
        font-style: italic;
        min-height: 24px;
        padding-top: 5px;
    }

    .ui.icon.input {
        color: $color-white !important;

        @include mediaBreakpoint(mobile) { width: 100%; }

        input {
            @include fontWeight(light);
            border-radius: 0;
            border-color: $color-white;
            background: transparent;
            color: $color-white !important;
            height: 34px;

            &:focus {
                color: $color-white !important;
                border-color: $color-blue;
            }
        }

        &.success {
            i.icon {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }
        }

        i.icon {
            width: 35px;
            cursor: pointer;
            color: $color-white;
            opacity: 1;

            &:hover {
                color: $color-blue;
            }
        }

        ::-webkit-input-placeholder {
            color: $color-white;
        }

        ::-moz-placeholder {
            color: $color-white;
        }

        :-ms-input-placeholder {
            color: $color-white;
        }

        :-moz-placeholder {
            color: $color-white;
        }

        input:focus::-webkit-input-placeholder { color:transparent; }
        input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
        input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
        input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
    }
}
