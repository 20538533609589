// -------------------------------------------------------------
// This file contains global styles for the It's a Monkey
// subscription modal window.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.small.modal.subscribe {
    padding-bottom: 80px;

    .header {
        padding-top: 0px;
    }

    .content {
        .ui.form.subscribe {
            padding-bottom: 20px;

            input {
                @include fontWeight(light);
                background-color: $color-white;
                color: $color-grey-dark !important;
                width: 353px;
                text-align: left;
            }

            i.icon {
                color: $color-green;
            }

            .message {
                padding-top: 10px;
            }

            .ui.button {
                width: 100%;
                margin-right: 0 !important;
                margin-top: 20px;

                @include mediaBreakpoint(mobile) { width: 100% !important; }
            }

            .ui.dimmer .ui.loader:after {
                border-color: $color-green transparent transparent !important;
            }

            .ui.dimmer .ui.loader:before {
                border-color: lighten($color-green, 30%) !important;
            }
        }

        .buttons {
            @include mediaBreakpoint(mobile) { padding-bottom: 20px !important; }

            .ui.button {
                width: 100%;
                margin-right: 0 !important;
                display: block;

                i {
                    @include mediaBreakpoint(smobile) { display: none !important; }
                }

                &:first-child {
                    float:  left;
                    margin-bottom: 20px;
                }

                &:last-child {
                    float: right;
                }
            }
        }
    }
}
