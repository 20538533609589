@import 'libs/mixins';

.episodes-segment {
    .ui.text.container {
        @include mediaBreakpoint(tablet) {
            width: 100% !important;
            margin-left: 0 !important;
            padding-top: 20px;
            padding-left: 1em !important;
            padding-right: 1em !important;
        }
        @include mediaBreakpoint(mobile) {
            width: 100% !important;
            margin-left: 0 !important;
            padding-top: 20px;
            padding-left: 1em !important;
            padding-right: 1em !important;
        }
    }

    .ui.header {
        padding-bottom: 40px !important;

        @include mediaBreakpoint(mobile) {
          padding-bottom: 0px !important;
          margin-bottom: 0px !important;
          padding-left: 5px;
        }
    }

    .ui.grid > * {
        padding-top: 0 !important;

        @include mediaBreakpoint(mobile) {
            padding: 20px !important;
        }
    }

    .ui.grid>[class*="five wide"].column {
        @include mediaBreakpoint(mobile) {
            width: 100% !important;
        }
    }

    .ui.grid>[class*="eleven wide"].column {
        @include mediaBreakpoint(mobile) {
            width: 100% !important;
        }
    }
}
