// -------------------------------------------------------------
// This file contains styles for ManageFlitter footer segment.
// -------------------------------------------------------------

@import 'libs/mixins';

.copyright {
    @include fontWeight(light);
    background-color: $color-grey-dark;
    width: 100%;
    height: auto;
    color: lighten($color-grey-dark, 20%);
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    margin-top: 0;
    position: relative;
    overflow-wrap: break-word;

    a {
        color: lighten($color-grey-dark, 30%) !important;

        &:hover {
            color: $color-grey !important;
        }
    }
}

.ui.vertical.segment.footer {
    padding-top: 0;
    @include fontWeight(light);
    background: url('/static/img/footer.jpg') no-repeat;
    padding-bottom: 20px;
    width: 100%;
    color: $color-white !important;
    font-size: 18px;

    @include mediaBreakpoint(large) { background-size: 100%; }
    @include mediaBreakpoint(tablet) { background-size: cover; padding-bottom: 20px; }
    @include mediaBreakpoint(mobile) { background-size: cover; padding-bottom: 20px; }

    .ui.container {
        padding-top: 50px;
    }

    a {
        color: $color-white;
        padding-bottom: 10px;

        &:hover {
            color: $color-white;
        }
    }

    .item {
        color: $color-white;
    }

    .logo {
        margin-top: 10px;
        background: transparent no-repeat top left;
        background-image: url('/static/img/manageflitter.png');
        background-size: contain;
        text-decoration: none;
        display: block;
        width: 214px;
        height: 56px;

        @include mediaBreakpoint(tablet) {
            display: inline-block;
            vertical-align: middle;
        }

        @include mediaBreakpoint(mobile) {
            display: inline-block;
            vertical-align: middle;
            margin-right: 35px;
        }
    }

    .ui.header {
        @include fontWeight(normal);
        padding-top: 50px;
        text-align: center;
        font-size: 40px;
        color: $color-white;

        @include mediaBreakpoint(tablet) { font-size: 30px; }
        @include mediaBreakpoint(mobile) { font-size: 30px; }
    }

    .ui.subscribe.form {

        .ui.popup.error {
            top: 98%;
            left: 35px;

            @include mediaBreakpoint(tablet) { left: 30%; }
            @include mediaBreakpoint(mobile) { left: 0; }

            &:before {
                left: 85px;

                @include mediaBreakpoint(mobile) { left: 15px; }
            }
        }

        input {
            width: 250px;
        }
    }

    .message-us {
        @include fontWeight(light);
        font-size: 35px;
        line-height: 45px;
        padding-top: 20px;
        padding-bottom: 70px;
        margin: 0 auto;
        width: 70%;

        @include mediaBreakpoint(tablet) { font-size: 20px; line-height: 27px; width: 80%; }
        @include mediaBreakpoint(mobile) { font-size: 20px; line-height: 27px; width: 100%; }

        .ui.button {
            margin-top: 70px;
            background-color: $color-blue;
            color: $color-white !important;
            border-color: $color-blue;

            @include mediaBreakpoint(tablet) { margin-top: 50px; }
            @include mediaBreakpoint(mobile) { margin-top: 50px; }

            &:hover {
                background-color: $color-blue-dark;
                border-color: $color-blue-dark;
            }
        }
    }

    .column {
        @include mediaBreakpoint(tablet) { width: 100%; }

        .hide {
            @include mediaBreakpoint(tablet) { display: none; }
            @include mediaBreakpoint(mobile) { display: none; }
        }

        &.left.aligned {
            @include mediaBreakpoint(tablet) { padding-top: 36px !important; text-align: center; }
            @include mediaBreakpoint(mobile) { padding-top: 36px !important; text-align: left; }

            .social_buttons {
                @include mediaBreakpoint(mobile) { display: inline-block; }

                i.icon {
                    @include mediaBreakpoint(mobile) { width: 0.97em; }
                }
            }
        }

        &.center.aligned {
            padding-top: 36px !important;

            @include mediaBreakpoint(mobile) { padding-top: 0 !important; }
        }

        &.right.aligned {
            padding-top: 36px !important;

            @include mediaBreakpoint(tablet) { text-align: center; }
            @include mediaBreakpoint(mobile) { text-align: left; }
        }
    }

    .social_buttons {
        padding-top: 20px;

        @include mediaBreakpoint(tablet) { padding-top: 10px; }
        @include mediaBreakpoint(mobile) { padding-top: 10px; }

        .itunes {
            font-size: 1.2em;
        }

        .fa-instagram {
            color: #6C6C6C;
            font-size: 1.4em;
        }

        a {
            padding: 0;
            margin: 0;

            &:hover {
                color: $color-white;
            }
        }
    }
}
