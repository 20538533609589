// -------------------------------------------------------------
// This file contains styles for the Guests section on
// the It's A Monkey homepage.
// -------------------------------------------------------------

@import 'libs/mixins';

.guests {
    width: 100%;
    font-size: 17px;
    color: $color-white;
    background-color: $color-blue;
    line-height: 67px;
    height: 67px;

    a {
      color: $color-white;
    }

    .item {
        @include fontWeight(light);
        display: inline-block;
        padding-right: 10px;

        &:after {
            padding-left: 10px;
            content: '•';
        }

        &:first-child:after, &:last-child:after {
            content: ''
        }

        &.title {
            @include fontWeight(bold);
        }
    }
}
