// -------------------------------------------------------------
// This file contains general styles for the segment containers
// that contain various ManageFlitter content.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.segments {
    border: none;
    box-shadow: none;
    background-color: $color-white-primary;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    z-index: 4;

    .ui.vertical.segment:first-child {
        border-radius: 0 !important;
        padding-top: 64px !important;

        @include mediaBreakpoint(tablet) { padding-top: 30px !important;  margin-top: 0 !important; }
        @include mediaBreakpoint(mobile) { padding-top: 0px !important; margin-top: 0 !important; }
    }

    .ui.vertical.segment {
        border: none;
        margin-bottom: 100px !important;
        padding-top: 0;
        padding-bottom: 0;
        background-color: $color-white-primary;

        @include mediaBreakpoint(tablet) { margin-bottom: 50px !important; }
        @include mediaBreakpoint(mobile) { margin-bottom: 50px !important; }

        &.preview {
              color: #468847;
              background-color: #DFF0D8;
              border-color: 1px solid #D6E9C6;
              padding: 8px 35px 8px 14px;
              margin-bottom: 20px;
              text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
              border-radius: 4px;
              margin-top: 0 !important;
        }

        &.alternate {
            background-color: #E9E9E9;
            padding-top: 80px;
            padding-bottom: 80px;

            @include mediaBreakpoint(tablet) { padding-top: 40px; padding-bottom: 40px; }
            @include mediaBreakpoint(mobile) { padding-top: 40px; padding-bottom: 40px; }

            .ui.header {
                @include mediaBreakpoint(tablet) { display: block !important; }
                @include mediaBreakpoint(mobile) { display: block !important; }
            }

            .ui.card {
              background-color: #E9E9E9;
            }

            .interview {
                padding-bottom: 0 !important;
            }

            .name {
                font-size: 20px !important;
            }
        }

        &.about {
          margin-bottom: 0 !important;
        }

        &.pattern {
            padding-top: 106px;
            padding-bottom: 106px;
            background: url('/static/img/pattern_blue.png');

            @include mediaBreakpoint(tablet) { padding-top: 50px; padding-bottom: 50px; }
            @include mediaBreakpoint(mobile) { padding-top: 50px; padding-bottom: 50px; }

            .ui.header {
                color: $color-white;

                .title {
                    background-color: transparent;
                    color: $color-white;
                }
            }
        }

        &.subscribe {
            @include mediaBreakpoint(mobile) {
                margin-bottom: 25px !important;
            }

            .ui.text.container {
                @include mediaBreakpoint(tablet) {
                  padding-left: 20px !important;
                  padding-right: 20px !important;
                }

                @include mediaBreakpoint(mobile) {
                    margin: 0 !important;
                    padding-right: 40px !important;
                    padding-left: 40px !important;
                }
            }

            .subscribe-buttons {
                padding-bottom: 0 !important;
            }

            .column {
                @include mediaBreakpoint(tablet) { width: 100%; }
                @include mediaBreakpoint(mobile) { width: 100%; padding: 0; }
            }

            .ui.form.subscribe {
                width: 100%;

                @include mediaBreakpoint(tablet) { padding-top: 10px; }
                @include mediaBreakpoint(mobile) { padding-top: 10px; }

                &.success {
                    .ui.icon.input > input, textarea {
                        border-color: $color-grey-dark !important;
                    }
                }

                .ui.icon.input {
                    width: 100% !important;

                    input {
                        font-size: 35px !important;
                        border-color: $color-grey-dark;
                        //border-right: 0 !important;
                        font-style: italic;
                        color: $color-grey-dark !important;
                        height: 80px;
                        width: 100%;

                        @include mediaBreakpoint(tablet) {
                            width: 100%;
                            border-right: 1px solid $color-grey-dark !important;
                        }

                        @include mediaBreakpoint(mobile) {
                            width: 100%;
                            height: 34px;
                            font-size: 16px !important;
                            border-right: 1px solid $color-grey-dark !important;
                        }
                    }

                    .indicator {
                        background-color: $color-blue;
                        color: $color-white;
                        position: absolute;
                        text-transform: uppercase;
                        line-height: 80px;
                        text-align: right;
                        padding: 0 10px 0 10px;
                        width: 25%;
                        top: 0;
                        right: 0;
                        margin: 0;
                        height: 100%;

                        @include mediaBreakpoint(tablet) { display: none; }
                        @include mediaBreakpoint(mobile) { display: none; }

                        .text {
                            font-size: 35px !important;
                            padding-right: 20px;

                            a {
                                color: $color-white;
                            }

                            i {
                                color: $color-white !important;
                                padding-left: 10px;
                            }
                        }

                        .ui.active.dimmer {
                            background-color: $color-blue;
                            font-size: 1em !important;
                        }
                    }

                    i.alternative {
                        display: none;
                        color: $color-blue !important;

                        @include mediaBreakpoint(tablet) {
                            display: block;
                            font-size: 2.5em;
                            margin-right: 10px !important;
                        }

                        @include mediaBreakpoint(mobile) {
                            display: block;
                        }
                    }
                }

                .ui.button {
                    font-size: 35px !important;
                    background-color: $color-blue;
                    border-color: $color-blue;
                    color: $color-white !important;
                    height: 80px;
                    width: 25%;
                    margin-right: 0 !important;

                    @include mediaBreakpoint(tablet) {
                        display: none;
                    }

                    @include mediaBreakpoint(mobile) {
                        display: none;
                    }

                    i {
                        color: $color-white !important;
                        display: inline-block !important;
                    }

                    &:hover {
                        background-color: $color-blue-dark !important;
                    }
                }

                .message {
                    color: $color-grey-dark;
                    font-size: 17px;
                }

                ::-webkit-input-placeholder {
                    color: $color-grey;
                }

                ::-moz-placeholder {
                    color: $color-grey;
                }

                :-ms-input-placeholder {
                    color: $color-grey;
                }

                :-moz-placeholder {
                    color: $color-grey;
                }
            }
        }

        .ui.text.container {
            max-width: $text-container-width !important;
            width: $text-container-width !important;
            color: $color-grey-primary;
            font-size: $font-size-xsmall;
            z-index: 10000;

            @include mediaBreakpoint(tablet) { width: 100% !important; max-width: 100% !important; }
            @include mediaBreakpoint(mobile) { width: 100% !important; max-width: 100% !important; }
        }

        .ui.fluid.container {
            padding-left: 50px;
            padding-right: 50px;

            @include mediaBreakpoint(large) {
                padding-left: 250px;
                padding-right: 250px;
            }
        }

        .header-col {
            overflow: hidden;

            @include mediaBreakpoint(mobile) { margin: 0 auto; }

            .ui.header {
                display: inline-block;
                float: left;
            }

            .ui.form {
                display: inline-block;
                float: right;

                i.icon {
                    cursor: pointer;
                    width: 35px;
                    z-index: 3;
                    opacity: 1;
                    color: $color-green;

                    &:hover {
                        color: $color-blue !important;
                    }
                }

                input {
                    @include fontWeight(light);
                    color: $color-grey-dark;
                    border-radius: 0;
                    border-color: $color-grey-dark;

                    &:focus {
                        border-color: $color-green;
                    }
                }

                ::-webkit-input-placeholder {
                    color: $color-grey-dark;
                }

                ::-moz-placeholder {
                    color: $color-grey-dark;
                }

                :-ms-input-placeholder {
                    color: $color-grey-dark;
                }

                :-moz-placeholder {
                    color: $color-grey-dark;
                }

                @include mediaBreakpoint(tablet) {
                    display: block !important;
                    float: left;
                    margin-top: 10px;
                }

                @include mediaBreakpoint(mobile) {
                    display: block !important;
                    float: left;
                    margin-top: 10px;
                }
            }
        }

        .ui.header {
            @include fontWeight(light);
            padding-bottom: 60px;
            color: #414141;
            font-size: 35px;
            padding-top: 0;
            text-transform: uppercase;

            @include mediaBreakpoint(tablet) { font-size: 20px; padding-bottom: 20px; }
            @include mediaBreakpoint(mobile) { font-size: 20px; padding-bottom: 20px; }

            .title {
                @include fontWeight(bold);
                color: #414141;
                background-color: transparent;
                padding-left: 0;
            }
        }

        p {
            @include fontWeight(light);
            color: $color-grey-primary;
            font-size: $font-size-xsmall;
            line-height: 30px;
        }
    }

    .ui.vertical.segment:last-child {
        padding-bottom: 100px;
        margin-bottom: 0 !important;

        @include mediaBreakpoint(tablet) { padding-bottom: 50px; }
        @include mediaBreakpoint(mobile) { padding-bottom: 50px; }
    }
}
