@import 'libs/mixins';

.ui.vertical.masthead.episode {

    .episode-title {
        font-size: 17px;
    }

    .episode-guest {
        font-size: 35px;
        text-transform: uppercase;

        .guest-title {
            display: block;
            font-size: 25px;
            padding-top: 5px;
            text-transform: none;
        }

        .guest-link {
            font-size: 20px;
            display: block;
            text-transform: none;
        }
    }

    .episode-buttons {
        padding-top: 20px;
    }
}
