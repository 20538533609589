// -------------------------------------------------------------
// This file contains styles for the slider mobile menu.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.vertical.sidebar.menu {
    background-color: #2D2D2D;
    width: 200px;

    a.item {
        color: #BFBFBF;
        font-size: 20px;
        border: 0;
        box-shadow: none;

        &:hover {
            color: $color-blue;
        }

        &:before {
            background: transparent;
        }

        &.secondary {
            font-size: 15px;
            padding-left: 1.7em;
            text-transform: uppercase;
        }
    }

    .menu-divider {
        width: 22px;
        margin: 0.9em 0 1em 1.7em;
        border-top: 2px solid $color-blue;
    }
}
