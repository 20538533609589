@import 'libs/mixins';

.transcript-container {
    background-color: $color-white;
}

.disqus {
  .ui.text.container {
    @include mediaBreakpoint(tablet) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include mediaBreakpoint(mobile) {
      padding-top: 20px;
      padding-right: 40px;
    }
  }
}

.hosts-container {
    background-color: $color-blue;
    background: url('/static/img/pattern_blue.png');

    .ui.grid>[class*="four wide"].column:nth-child(2) {
        @include mediaBreakpoint(tablet) {
            width: 70% !important;
        }
    }

    .hosts {
        max-width: $text-container-width !important;
        width: $text-container-width !important;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;

        @include mediaBreakpoint(tablet) {
          padding-left: 20px;
          padding-right: 20px;
        }

        @include mediaBreakpoint(mobile) {
          width: 100% !important;
          padding-top: 30px;
          padding-bottom: 30px;
        }
    }

    .host-name {
        @include fontWeight(normal);
        line-height: 34px;
        font-size: 25px;
        text-transform: uppercase;
        color: $color-white;

        @include mediaBreakpoint(tablet) {
            font-size: 25px;
        }
        @include mediaBreakpoint(mobile) {
            padding-top: 5px;
            margin-bottom: 0;
            font-size: 20px;
            height: auto;
        }
    }

    .host-link {
        font-size: 20px;
        padding-top: 10px;
        @include fontWeight(normal);

        a {
            color: $color-white;

            &:hover {
                color: $color-white;
            }
        }
    }

    .host-desc-divider {
        margin-top: 20px;
        padding-bottom: 10px;
        border-top: 1px solid $color-white;
        width: 20%;
    }

    .host-desc {
        width: 80%;
        @include fontWeight(light);
        line-height: 27px;
        color: $color-white;

        font-size: 17px;
    }

    img {
        width: 100%;
    }
}
