// -------------------------------------------------------------
// This file contains styles for the masthead on the homepage
// and individual pages.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.vertical.masthead {
    top: 0;
    position: relative;
    width: 100%;
    min-height: 550px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    @include fontWeight(normal);
    color: $color-white-primary;
    text-align: center;
    overflow: auto;
    height: auto;
    z-index: 2;
    background: transparent;
    background: url('/static/img/header.jpg') no-repeat;
    background-position: 0% 100%;
    background-size: cover;
    background-color: $color-blue;

    &.episodes {
        background-position: 0 38%;
        background-color: $color-white;
        overflow: hidden;
        min-height: 300px;
        background-size: 100%;

        @include mediaBreakpoint(tablet) { background-size: cover; }
        @include mediaBreakpoint(mobile) { background-size: cover; }

        .heading {
            top: 70px;

            @include mediaBreakpoint(tablet) {
              padding: 0;
              top: 70px;
            }

            @include mediaBreakpoint(mobile) {
              padding: 0;
              top: 100px;
            }
        }

        p {
            color: $color-white !important;
        }

        .ui.header {
          margin-bottom: 0;
          display: inline-block;
          float: left;
          color: $color-white !important;

          .title {
            color: $color-white !important;
          }

          @include mediaBreakpoint(tablet) {
            font-size: 30px !important;
          }

          @include mediaBreakpoint(mobile) {
            font-size: 30px !important;
          }
        }

        .header-col {
            @include mediaBreakpoint(tablet) {
                width: 100%;
            }

            @include mediaBreakpoint(mobile) {
                width: 100%;
            }
        }
    }

    @include mediaBreakpoint(large) { background-position: 0% 50%; }

    .heading {
        @include fontWeight(light);
        position: relative;
        top: 140px;
        left: 0;
        right: 0;
        font-size: 17px;
        line-height: 27px;
        margin: 0 auto;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;

        @include mediaBreakpoint(smobile) { top: 100px; }

        a {
          color: $color-white;
        }

        b {
          @include fontWeight(normal);
        }

        .logo {
            object-fit: cover;
            position: static;
            width: 500px;

            @include mediaBreakpoint(mobile) { width: 300px; }
        }

        .introduction {
            font-size: 35px;
            line-height: 40px;
            padding-top: 25px;

            @include mediaBreakpoint(mobile) { font-size: 30px; line-height: 35px; }
        }

        .sub-heading {
            font-size: 25px;
            padding-top: 10px;
            display: block;

            @include mediaBreakpoint(mobile) { font-size: 20px; }
        }

        .break {
            display: block;

            @include mediaBreakpoint(mobile) { display: none; }
        }

        .mf-logo {
          vertical-align: top;
          display: inline;
          padding-left: 2px;
        }
    }

    .ui.form.subscribe {
        padding-top: 10px !important;

        input {
            width: 300px !important;
        }
    }
}
