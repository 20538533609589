// -------------------------------------------------------------
// This file contains styles for the transcript component.
// -------------------------------------------------------------

@import 'libs/mixins';

iframe {
    border: none;
    background-color: $color-white;
}

.transcript {
    background-color: $color-green;
    padding-bottom: 20px;
    display: none;
}

.transcript-view {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #ccc;
    border-radius:8px;
    height: 350px;
    margin: 0 auto;
    width: 37% !important;

    @include mediaBreakpoint(tablet) { width: 90% !important; }
    @include mediaBreakpoint(mobile) { width: 90% !important; }
}

.transcript-button {
    text-align: center;
    background-color: $color-green;

    .ui.button {
        @include fontWeight(normal);
        background-color: $color-green !important;
        color: $color-white !important;
        padding-top: 20px;
        padding-bottom: 40px;
        width: 100%;
        font-size: 17px;
        border-color: $color-green !important;

        &:hover {
            background-color: $color-green !important;
            border-color: $color-green !important;
        }
    }
}
