// -------------------------------------------------------------
// This file contains styles for the Button component.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.button, .ui.button:active, .ui.button:focus {
    @include fontWeight(normal);
    font-size: 20px !important;
    background-color: $color-green;
    margin-right: 20px !important;
    margin-bottom: 10px;
    padding: 0;
    border-radius: 0px;
    height: 34px;
    width: 250px;
    line-height: 34px;
    text-transform: uppercase;
    border: 1px solid $color-green;
    color: $color-white;

    @include mediaBreakpoint(mobile) { width: 100%; }

    &:hover {
        background-color: $color-green-dark;
        border-color: $color-green-dark;
        color: $color-white;
    }

    &.blue {
        background-color: $color-blue;
        border-color: $color-blue;

        &:hover {
            background-color: $color-blue-dark;
            border-color: $color-blue-dark;
        }
    }

    &.read-more {
      @include fontWeight(light);
      text-transform: none;
      width: 110px !important;
      font-size: 17px !important;
      background-color: $color-white !important;
      border: 1px solid $color-grey-dark;
      color: $color-grey-dark;

      &:hover {
        border-color: $color-blue;
        color: $color-blue;
      }
    }

    &.subscribe {
        @include fontWeight(light);
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-transform: uppercase;
        font-size: 35px !important;
        background-color: $color-white !important;
        border: 1px solid $color-grey-dark;
        color: $color-grey-dark;

        @include mediaBreakpoint(tablet) {
            @include fontWeight(normal);
            border-color: $color-grey-dark;
        }

        @include mediaBreakpoint(mobile) {
            @include fontWeight(normal);
            width: 100% !important;
            font-size: 17px !important;
            border-color: $color-grey-dark;
            line-height: 34px;
            display: block;
            height: 34px;
        }

        &:first-child {
            margin-right: 40px !important;

            @include mediaBreakpoint(tablet) { margin-right: 20px !important; margin-bottom: 20px; }
            @include mediaBreakpoint(mobile) { margin-right: 20px !important; margin-bottom: 20px; }
        }

        .fa-stack {
            height: 2.3em;
            font-size: 0.6em;
        }

        &.itunes:hover {
            color: $color-blue;
            border-color: $color-blue;

            i {
                color: $color-blue;
            }
        }

        &.rss:hover {
            color: $color-green;
            border-color: $color-green;
        }
    }
}
