@import 'libs/mixins';

.social {
    display: inline-block;
    padding-right: 20px;

    @include mediaBreakpoint(mobile) { display: block; }

    ul {
        list-style: none;
        padding-left: 0px;
    }
}

.social__hidden {
    display: inline-block;
}

.social__item {
    display: inline-block;
    font-size: 15px;

    .fa-lg {
        font-size: 15px;
    }

    .fa-stack-1x {
        color: $color-white;
    }

    .twitter {
        .fa-circle {
            color: $color-blue;
        }
    }

    .facebook {
        .fa-circle {
            color: #3b5998;
        }
    }

    .gplus {
        .fa-circle {
            color: #d34836;
        }
    }

    .pinterest {
        .fa-circle {
            color: #bd081c;
        }
    }

    .rss {
        .fa-circle {
            color: #ee802f;
        }
    }

    .apple {
        .fa-circle {
            color: $color-black;
        }
    }
}

.social__item--hidden {
    display: none;

    &.toggle {
        display: inline-block;
    }
}

.social__item--label {
    @extend .social__item;
    margin-right: 10px;

    @include mediaBreakpoint(mobile) { display: block; }
}
