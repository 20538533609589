// -------------------------------------------------------------
// This file contains global styles for the It's a Monkey
// Monkey contact modal window.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.dimmer.modals .scrolling.modal {
    position: relative !important;
    top: auto !important;
    left: auto;
}

.ui.small.modal {
	width: 475px;
	position: static!important;
    margin: 3.5rem auto!important;
	background: $color-blue;
	border-radius: 0;
	padding: 40px;
	padding-bottom: 0;

	@include mediaBreakpoint(mobile) { width: 100%; padding: 20px; }

	.close {
		color: $color-white !important;
		top: .5rem !important;
    	right: .5rem !important;
	}

	.header {
		@include fontWeight(light);
		border: none;
		font-size: 30px !important;
		background: $color-blue;
		color: $color-white;
		text-transform: uppercase;
	}

	.content {
		padding-top: 10px;
		@include fontWeight(normal);
		background: $color-blue;
		font-size: 17px;
		line-height: 27px;
		color: $color-white;

		.description {
			padding-bottom: 40px;
			padding-top: 40px;

			&:first-child {
				padding-top: 0px;
			}
		}

		.ui.form.subscribe {
			input {
				@include fontWeight(light);
				background-color: $color-white;
				color: $color-grey-dark !important;
				width: 353px;

				&:focus {
					border-color: $color-green;
				}
			}

			.message {
				padding-top: 10px;
				text-align: center;
			}

			.ui.button {
				width: 100%;
				margin-right: 0 !important;
				margin-top: 20px;

				@include mediaBreakpoint(mobile) { width: 100% !important; }
			}

			::-webkit-input-placeholder {
	            color: $color-grey-dark;
	        }

	        ::-moz-placeholder {
	            color: $color-grey-dark;
	        }

	        :-ms-input-placeholder {
	            color: $color-grey-dark;
	        }

	        :-moz-placeholder {
	            color: $color-grey-dark;
	        }
		}
	}
}