// -------------------------------------------------------------
// This file contains styles for the About section on
// the It's A Monkey homepage.
// -------------------------------------------------------------

@import 'libs/mixins';

.about {
    a {
        color: $color-white;

        &:hover {
            color: $color-hover;
        }
    }

    .ui.header {
      @include mediaBreakpoint(tablet) { padding-left: 20px; }
    }

    .ui.grid {
        @include mediaBreakpoint(tablet) { margin: -1.11rem !important; }
        @include mediaBreakpoint(mobile) { margin: -1.11rem !important; }
    }

    .ui.grid > .photo.column {
        padding-right: 0;

        @include mediaBreakpoint(tablet) { padding: 0; width: 100% !important; }
        @include mediaBreakpoint(mobile) { padding: 0; width: 100% !important; }
    }

    .ui.grid > .about-content.column {
        padding-left: 0;
        padding-right: 0;
        position: relative;

        @include mediaBreakpoint(tablet) { padding: 0; width: 100% !important; }
        @include mediaBreakpoint(mobile) { padding: 0; width: 100% !important; }
    }

    .image {
        width: 573px;
        height: 417px;
        position: relative;
        left: 0;
        object-fit: cover;

        @include mediaBreakpoint(tablet) { width: 100%; height: auto; }
        @include mediaBreakpoint(mobile) { width: 100%; height: auto; }
    }

    .heading {
        @include fontWeight(normal);
        font-size: 40px;
        line-height: 45px;
        background-color: $color-blue-dark;
        color: $color-white;
        width: 100%;
        padding: 20px 40px 20px 40px;

        @include mediaBreakpoint(tablet) { width: 100%; font-size: 20px; line-height: 27px; }
        @include mediaBreakpoint(mobile) { width: 100%; font-size: 20px; padding-left: 20px; line-height: 27px; }
    }

    .description {
        @include fontWeight(normal);
        color: $color-white;
        font-size: 17px;
        line-height: 27px;
        padding-left: 40px;
        padding-top: 30px;
        padding-right: 0;

        @include mediaBreakpoint(tablet) { width: 100%; }
        @include mediaBreakpoint(mobile) { width: 100%; padding-left: 20px; padding-right: 30px; }
    }

    .buttons {
        padding-left: 40px;
        padding-right: 0 !important;
        position: absolute;
        width: 100%;
        bottom: 14px;

        @include mediaBreakpoint(tablet) {
            position: static;
            margin-top: 30px;
            padding-right: 50px !important;
            width: 100%;
        }

        @include mediaBreakpoint(mobile) {
            position: static;
            margin-top: 30px;
            padding-left: 20px !important;
            padding-right: 50px !important;
            width: 100%;
        }

        .ui.button {
            margin-right: 0 !important;
            margin-bottom: 0 !important;

            @include mediaBreakpoint(mobile) { margin-bottom: 20px !important; }

            &:focus {
                margin-right: 0 !important;
            }

            &:last-child {
                float: right;
            }
        }
    }
}
