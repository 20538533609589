// -------------------------------------------------------------
// This file contains styles for the form errors.
// -------------------------------------------------------------

@import 'libs/mixins';

.ui.popup.error {
    box-sizing: content-box;
    padding-left: 20px;
    background-color: #C04A46;
    top: 90%; right: auto; left: 10px; bottom: auto;
    display: block !important;
    border-radius: 0px;
    border: none;
    box-shadow: none;
    max-width: none !important;
    text-align: left;

    @include mediaBreakpoint(mobile) { padding: 5px; padding-left: 10px; left: auto; }

    &.hidden {
        display: none !important;
    }

    &:before {
        transform: rotate(225deg);
        left: 15px;
        bottom: 35px;
        background-color: #C04A46 !important;
        box-shadow: none;

        @include mediaBreakpoint(mobile) { bottom: 23px; }
        @include mediaBreakpoint(smobile) { bottom: 39px; }
    }

   .content {
        width: 100%;
        font-size: 13.5px !important;
        background-color: #C04A46 !important;
        color: $color-white !important;
        padding-top: 0 !important;
        line-height: normal !important;
        @include fontWeight(normal);

        i {
            color: $color-white !important;
            font-size: 14px;
        }
   }
}
